<template>
  <div>
    <v-btn
      color="blue"
      class="ma-2 white--text"
      block
      @click="open"
      v-if="!this.$store.state.one_drive_token"
      >Login to OneDrive</v-btn
    >
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      color="deep-purple accent-4"
    ></v-progress-linear>
    <file-list
      :files="computedFiles"
      @fetchFiles="fetchFiles"
      @downloadFile="downloadFile"
      @shareFile="shareFile"
      @move="move"
      @deleteFile="deleteFile"
      @filesUploaded="filesUploaded"
      @renameFile="renameFile"
      v-if="files.length > 0"
    ></file-list>
    <share :file="currentFile" :key="currentFile.id" v-if="currentFile" />
  </div>
</template>
<script>
var Url = require("url-parse");
import axios from "axios";
import util from "@/util/util";
import fileList from "@/components/File/file-list.vue";
import share from "@/components/File/OneDrive/share.vue";
export default {
  components: {
    fileList,
    share,
  },
  data() {
    return {
      files: [],
      baseUrl: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
      client_id: "4bbf43f5-6c7e-46ba-91f6-3c62d72a4e47",
      scope: "files.readwrite.all",
      response_type: "token",
      redirect_uri:
        window.location.protocol +
        "//" +
        window.location.host +
        "/files/onedrive",
      currentFile: null,
      loading: false,
    };
  },
  mounted() {
    console.log("mounted");
    console.log(this.redirect_uri);
    var url = new Url(window.location.href.replace("#", "?"), true);
    console.log(url);
    if (url.query.access_token) {
      this.$store.commit("setOneDriveToken", url.query.access_token);
      this.$router.push("/files/onedrive");
    }
    if (this.$store.state.one_drive_token) {
      this.fetchFiles({ name: "Root", id: "root", folder: true });
    }
    util.event.$on("file-shared", () => {
      this.$store.state.showDropboxShareDialog = false;
    });
    util.event.$off("refreshFileList");
    util.event.$on("refreshFileList", () => {
      this.fetchFiles(
        this.$store.state.stack[this.$store.state.stack.length - 1]
      );
    });
  },
  methods: {
    filesUploaded(files, parent) {
      util.event.$emit("filesUploaded", files, parent);
    },
    deleteFile(file) {
      this.loading = true;
      axios({
        url: "https://graph.microsoft.com/v1.0/me/drive/items/" + file.id,
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + this.$store.state.one_drive_token,
        },
      })
        .then((resp) => {
          console.log(resp);
          this.fetchFiles(
            this.$store.state.stack[this.$store.state.stack.length - 1]
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    move(source, destination) {
      this.loading = true;
      axios({
        url: "https://graph.microsoft.com/v1.0/me/drive/items/" + source.id,
        data: {
          parentReference: {
            id: destination.id,
          },
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.one_drive_token,
        },
        method: "PATCH",
      })
        .then((resp) => {
          console.log(resp);
          this.fetchFiles(
            this.$store.state.stack[this.$store.state.stack.length - 1]
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    shareFile(file) {
      this.currentFile = file;
      this.$store.commit("toggleOneDriveShareDialog", true);
    },
    downloadFile(file) {
      this.getFiles(file).then((resp) => {
        window.open(resp.data["@microsoft.graph.downloadUrl"]);
      });
    },
    fetchFiles(group) {
      this.$store.commit("addToStack", group);
      this.getFiles(group).then((resp) => {
        this.files = resp.data.value;
      });
    },
    getFiles(file) {
      this.loading = true;
      return axios
        .get(
          "https://graph.microsoft.com/v1.0/me/drive/items/" +
            file.id +
            (file.folder ? "/children" : "") +
            "?$expand=thumbnails",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.one_drive_token,
            },
          }
        )
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.commit("setOneDriveToken", null);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    renameFile(file, name) {
      this.loading = true;
      axios({
        url: "https://graph.microsoft.com/v1.0/me/drive/items/" + file.id,
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + this.$store.state.one_drive_token,
        },
        data: {
          name: name,
        },
      })
        .then(() => {
          util.notify(1, "Successfully Renamed");
          this.fetchFiles(
            this.$store.state.stack[this.$store.state.stack.length - 1]
          );
        })
        .finally(() => {
          this.$store.commit("toggleRenameDialog", false);
          this.loading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.commit("setOneDriveToken", null);
            } else {
              util.notify(0, e.response.data.error.message);
            }
          }
        });
    },
    open() {
      window.open(this.fullUrl);
      window.close();
    },
  },
  computed: {
    fullUrl() {
      return `${this.baseUrl}?client_id=${this.client_id}&scope=${this.scope}&response_type=${this.response_type}&redirect_uri=${this.redirect_uri}`;
    },

    computedFiles() {
      return this.files.map((f) => {
        let a = {
          id: f.id,
          folder: f.folder,
          thumbnails: f.thumbnails,
          name: f.name,
          createdDateTime: f.createdDateTime,
          thumbnail: null,
        };
        if (f.thumbnails && f.thumbnails.length > 0 && f.thumbnails[0].large) {
          a.thumbnail = f.thumbnails[0].large.url;
        }
        return a;
      });
    },
  },
  watch: {
    "$store.state.one_drive_token": function(n) {
      localStorage.setItem("one_drive_token", n);
    },
  },
};
</script>
<style lang="scss">
.file-name {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}
</style>
