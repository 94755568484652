<template >
  <v-dialog v-model="$store.state.showOneDriveShareDialog" width="500">
    <v-card>
      <v-card-title
        class="text-h5"
        style="background-color: #0c2759; color: white"
      >
        Create A Shareable Link
      </v-card-title>

      <v-card-text>
        <v-select
          :items="['Edit', 'View', 'Embed']"
          label="Link Type"
          v-model="linkType"
        ></v-select>
        <v-textarea
          v-if="createdLink"
          :key="file.id"
          label="Shareable Link"
          v-model="createdLink"
          rows="2"
        ></v-textarea>
        <v-btn block color="primary" @click="createLink"> Create Link </v-btn>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import util from "@/util/util";
export default {
  props: ["file"],
  data() {
    return {
      dialog: false,
      linkType: "Edit",
      createdLink: "",
    };
  },
  methods: {
    createLink() {
      return axios({
        url:
          "https://graph.microsoft.com/v1.0/me/drive/items/" +
          this.$props.file.id +
          "/createLink",
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.$store.state.one_drive_token,
          "Content-type": "application/json",
        },
        data: {
          type: this.linkType,
          scope: "anonymous",
        },
      }).then(async (resp) => {
        this.createdLink =
          this.linkType == "Embed"
            ? resp.data.link.webHtml
            : resp.data.link.webUrl;
        this.$copyText(this.createdLink).then(() => {
          util.notify(1, "Link Copied to Clipboard");
        });
      });
    },
  },
};
</script>